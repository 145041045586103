<template>
  <v-dialog :value="value" persistent max-width="900px">
    <v-container fluid class="ma-0 pa-0">
      <v-card>
          <v-toolbar dense flat class="pl-4 pt-4">
            <v-icon class="mr-2 ml-0">
              {{ readOnly ? 'mdi-text-box-search-outline' : 'mdi-file-upload-outline' }}
            </v-icon>
            <v-toolbar-title class="title">
              {{ readOnly ? 'Contract Upload Details' : 'Upload Contract' }}
            </v-toolbar-title>
            <v-col cols="auto" class="ml-auto pr-1">
              <v-btn
                v-if="readOnly || ($auth.tenant !== 'jbg')"
               :loading="downloading" 
               :disabled="downloading" 
               :color="readOnly ? 'primary' : 'info'" 
               class="white--text" 
               @click="readOnly ? downloadContract() : downloadTemplate()">
                <v-icon left>mdi-file-download-outline</v-icon>
                {{ readOnly ? 'Contract File' : 'Template' }}
              </v-btn>
            </v-col>
            <v-col v-if="!readOnly" cols="auto" class="pr-0">
              <v-btn 
                color="primary"
                :loading="loading" 
                :disabled="uploadDisabled"
                @click="uploadFile">
                <v-icon left>mdi-file-upload-outline</v-icon>
                {{ reupload ? "Reupload" : "Upload" }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="pr-0">
              <v-btn icon @click="$emit('closeUpload')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-toolbar>
          <v-card-text class="py-4">
          <v-container fluid>
            <p class="pt-0 mt-0 pl-2 ml-2" style="color: grey; font-size: 16px">
              {{ modalSubtitle }}
            </p>
            <template>
              <v-form ref="form" lazy-validation v-model="valid">
                <v-row dense>
                  <v-col :cols="($auth.tenant === 'alliance-retail-group' ? undefined : 8)">
                    <template v-if="readOnly">
                      <v-text-field
                        v-model="selectedContract.file_name"
                        :inert="readOnly"
                        label="File Name"
                        dense
                        readonly
                        hide-details
                        prepend-icon="mdi-paperclip">
                      </v-text-field>
                    </template>
                    <template v-else>
                      <v-file-input
                        v-model="file"
                        :inert="readOnly"
                        outlined 
                        dense
                        hide-details="auto" 
                        truncate-length="100"
                        label="File*"
                        clearable
                        accept=".xlsx"
                        @change="handleFile">
                      </v-file-input>
                    </template>
                  </v-col>
                  <v-col cols="3" class="pt-0" v-if="tenant === 'pricechopper'">
                    <v-checkbox
                      v-if="showMultiVendor"
                      v-model="payload.multi_vendor"
                      label="Private Label Contract"
                      color="primary"
                      class="mt-0 pt-0"
                      dense
                      :disabled="readOnly && !payload.multi_vendor"
                      :readonly="readOnly && payload.multi_vendor"
                      :inert="readOnly"
                      hide-details
                      :ripple="false"
                      @change="multiVendorChange">
                    </v-checkbox>
                    <v-checkbox
                      v-model="payload.non_allowance"
                      label="Non-Allowance Contract"
                      color="primary"
                      dense
                      :disabled="readOnly && !payload.non_allowance"
                      :readonly="readOnly && payload.non_allowance"
                      :inert="readOnly"
                      hide-details
                      :ripple="false">
                    </v-checkbox>
                  </v-col>
                  <template v-if="tenant === 'alliance-retail-group'">
                    <v-col 
                      v-for="obj in contractBoolsConfig"
                      :key="obj.value"
                      align-self="center"
                      cols="auto">
                      <v-checkbox
                        v-model="payload[`${obj.value}`]"
                        :label="obj.label"
                        color="primary"
                        class="ma-auto"
                        dense
                        :readonly="readOnly && (payload[`${obj.value}`] === true)"
                        :disabled="(readOnly && !payload[`${obj.value}`]) || obj.disabled"
                        :inert="readOnly"
                        hide-details
                        @change="(e) => { return (obj.value === 'multi_vendor') ? multiVendorChange(e) : null }"
                        :ripple="false">
                      </v-checkbox>
                    </v-col>
                  </template>
                </v-row>
                
                <v-container fluid class="px-4">
                  <v-row dense class="mb-2" v-if="useVendorSelections">
                    <v-col cols="6">
                      <VendorSingleSelect
                        :disabled="payload.multi_vendor"
                        :readOnly="readOnly"
                        :value.sync="payload.vendor_party_id">
                      </VendorSingleSelect>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="payload.subvendor_party_id"
                        :disabled="subvendorDisabled"
                        :items="subvendors"
                        item-text="name"
                        item-value="id"
                        item-color="action"
                        label="Subvendor*"
                        :loading="loadingSubvendors"
                        :rules="[v => payload.vendor_party_id ? (!!v || 'Required field') : true]"
                        validate-on-blur
                        :clearable="!readOnly"
                        :readonly="readOnly && Boolean(payload.subvendor_party_id)"
                        :inert="readOnly"
                        background-color="#fff"
                        :dense="!readOnly"
                        :outlined="!readOnly"
                        hide-details="auto">
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row dense class="pb-2">
                    <v-col v-if="useCategoryManager" cols="6">
                      <v-autocomplete
                        v-model="payload.category_manager_id"
                        :items="categoryManagers"
                        item-text="name"
                        item-value="id"
                        item-color="action"
                        label="Category Manager*"
                        :clearable="!readOnly"
                        :rules="[v => !!v || 'Required field']"
                        :disabled="readOnly && !payload.category_manager_id"
                        :readonly="readOnly && Boolean(payload.category_manager_id)"
                        :inert="readOnly"
                        validate-on-blur
                        background-color="#fff"
                        :dense="!readOnly"
                        :outlined="!readOnly"
                        hide-details="auto">
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="tenant === 'alliance-retail-group'" cols="6">
                      <ContractAdGroups 
                        :value.sync="payload.ad_group_party_ids"
                        :readOnly="readOnly">
                      </ContractAdGroups>
                    </v-col>
                  </v-row>

                  <template v-if="tenant === 'alliance-retail-group'">
                    <v-divider class="my-2"></v-divider>
                      <v-row>
                        <v-col cols="2">
                          <v-text-field 
                            v-model="payload.lump_sum_allowance"
                            label="Ad Fee"
                            type="number"
                            min="0.00"
                            step=".01"
                            prefix="$"
                            :disabled="readOnly && !payload.lump_sum_allowance"
                            :readonly="readOnly && payload.lump_sum_allowance != null"
                            :outlined="!readOnly"
                            dense
                            hide-details="auto">
                          </v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field 
                            v-model="payload.tpr_fee"
                            label="TPR Fee"
                            type="number"
                            min="0.00"
                            step=".01"
                            prefix="$"
                            :disabled="readOnly && !payload.tpr_fee"
                            :readonly="readOnly && payload.tpr_fee != null"
                            :outlined="!readOnly"
                            dense
                            hide-details="auto">
                          </v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete
                            v-model="payload.ad_billback_category_id"
                            :items="adBillbackPromoCategories"
                            item-text="name"
                            item-value="id"
                            item-color="action"
                            label="Ad Billback Category"
                            :clearable="!readOnly"
                            :disabled="(readOnly && !payload.ad_billback_category_id)"
                            :readonly="readOnly && Boolean(payload.ad_billback_category_id)"
                            :inert="readOnly"
                            background-color="#fff"
                            dense
                            :outlined="!readOnly"
                            hide-details="auto"
                            @click:clear="resetSpecialAdLength"
                            @change="resetSpecialAdLength">
                          </v-autocomplete>
                        </v-col>
                      <v-col cols="4" align-self="center">
                        <v-checkbox
                          v-model="payload.special_ad_length"
                          label="Special Ad Length"
                          color="primary"
                          class="my-auto"
                          dense
                          :readonly="readOnly && payload.special_ad_length"
                          :disabled="!payload.ad_billback_category_id || (readOnly && !payload.special_ad_length) || (['Ad Billback - 1-2 Day Ad', 'Ad Billback Non-Allowance'].includes(selectedCategoryName))"
                          :inert="readOnly"
                          hide-details
                          :ripple="false">
                        </v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col align-self="center">
                        <v-text-field 
                          v-model="payload.digital_coupon_note"
                          label="Digital Coupon Value"
                          :disabled="!payload.digital_coupon"
                          :readonly="readOnly && payload.digital_coupon"
                          :inert="readOnly"
                          :outlined="!readOnly"
                          dense
                          hide-details="auto">
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" align-self="center">
                        <v-checkbox
                          v-model="payload.digital_coupon"
                          label="Digital Coupon"
                          color="primary"
                          class="my-auto"
                          :disabled="(readOnly && !payload.digital_coupon)"
                          :readonly="readOnly && payload.digital_coupon"
                          :inert="readOnly"
                          dense
                          hide-details
                          :ripple="false">
                        </v-checkbox>
                      </v-col>
                    </v-row>

                    <v-divider class="my-2"></v-divider>

                    <v-row dense class="pt-2">
                      <v-col 
                        v-for="config in contractDatesConfig"
                        :cols="config.cols || 4"
                        :key="config.value"
                        class="py-2">
                        <ContractDates
                          :ref="`${config.value}_dates`"
                          :value.sync="payload[`${config.value}`]"
                          :readOnly="readOnly"
                          :config="config"
                          :specialAdLength="payload.special_ad_length"
                          :ad_billback_category_id="payload.ad_billback_category_id"
                          hide-details="auto"
                          :error="showDateError">
                        </ContractDates>
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-form>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
    <UploadDialog v-if="dialog" v-model="dialog" :file="file" @cancel="closeUploadDialog"
      @confirmUpload="confirmUpload">
    </UploadDialog>
  </v-dialog>
</template>
<script>
// api
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import PartyRel from '@/axios/party-relationship-endpoint.js'
// components
import UploadDialog from '@/components/contracts/UploadDialog.vue'
import VendorSingleSelect from '@/components/VendorSingleSelect'
import ContractDates from '@/components/contracts/ContractDates.vue'
import ContractAdGroups from '@/components/contracts/ContractAdGroups.vue'
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  name: 'ContractUploadModal',
  data() {
    return {
      vendors: [],
      subvendors: [],
      loadingSubvendors: false,
      file: null,
      newFile: false,
      valid: true,
      loading: false,
      downloading: false,
      dialog: false,
      showDateError: false,
      payload: {
        vendor_party_id: null,
        subvendor_party_id: null,
        category_manager_id: null,
        ad_group_party_ids: [],
        multi_vendor: false,
        non_allowance: false,
        dsd: false,
        perishable: false,
        ad_billback_category_id: null,
        lump_sum_allowance: null,
        tpr_fee: null,
        special_ad_length: false,
        tpr: { start_dt: null, end_dt:  null },
        edlc: { start_dt: null, end_dt:  null },
        amap: { start_dt: null, end_dt:  null },
        eba: { start_dt: null, end_dt:  null },
        dsd_off_invoice: { start_dt: null, end_dt: null },
        ad_1: { start_dt: null, end_dt:  null },
        ad_2: { start_dt: null, end_dt:  null },
        ad_3: { start_dt: null, end_dt:  null },
        digital_coupon: false,
        digital_coupon_note: null
      }
    }
  },
  mixins: [displayAlert, userAccess, utils],
  components: { UploadDialog, VendorSingleSelect, ContractDates, ContractAdGroups },
  props: {
    value: Boolean,
    reupload: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    uploadId: {
      type: String,
      default: ""
    },
    selectedContract: Object
  },
  watch: {
    'payload.vendor_party_id': {
      async handler(newValue, oldValue) {
        if (newValue) {
          if (oldValue) {
            this.payload.subvendor_party_id = null
          }
          await this.updateSubvendors()
        }
      }
    },
    'payload.digital_coupon': {
      handler(newValue) {
        if (!this.readOnly && newValue === false) {
          this.payload.digital_coupon_note = null
        }
      }
    },
    noPromoDatesSelected: {
      handler(newValue) {
        if (newValue === false) {
          this.showDateError = false
        }
      }
    }
  },
  async created () {
    if ((this.reupload || this.readOnly) && this.selectedContract?.payload) {
      const payload = JSON.parse(JSON.stringify(this.selectedContract.payload))
      this.payload = payload
    }
    if (this.promo_categories.length === 0) {
      await this.$store.dispatch('getPromoCategories')
    }
  },
  computed: {
    modalSubtitle () {
      if (this.readOnly) {
        return 'Values entered for this contract'
      }
      return this.reupload ? "Reupload the contract file." : "Create promotions via contract file."
    },
    subvendorDisabled () {
      return !this.payload.vendor_party_id || this.payload.multi_vendor
    },
    useVendorSelections () {
      return ['alliance-retail-group', 'pricechopper'].includes(this.tenant) && !this.payload.multi_vendor
    },
    useCategoryManager () {
      return ['alliance-retail-group', 'pricechopper'].includes(this.tenant)
    },
    categoryManagers () {
      return this.$store.getters.categoryManagers
    },
    showMultiVendor () {
      return this.tenant === 'pricechopper' && (this.isAdmin || this.userPermissions.includes('contract:multi_vendor'))
    },
    adBillbackPromoCategories () {
      const categories = [
        'Ad Billback - 1-2 Day Ad', 'Ad Billback - 1 Week Ad', 'Ad Billback - 2 Week Ad', 
        'Ad Billback Non-Allowance', 'Ad Billback - PCE Meat', 'Ad Billback - Non Standard'
      ]
      return categories.flatMap(catName => {
        return this.userPromoCategories.find(cat => catName === cat?.name) || []
      })
    },
    selectedCategoryName() {
      if (this.payload.ad_billback_category_id) {
        const category = this.userPromoCategories.find(c => c?.id === this.payload.ad_billback_category_id)
        return category?.name
      }
      return null
    },
    nonAllowanceSelected() {
      return this.selectedCategoryName?.toLowerCase()?.includes('non-allowance') || false
    },
    nonAllowanceCategoryId() {
      const category = this.adBillbackPromoCategories.find(c => c?.name === 'Ad Billback Non-Allowance')
      return category?.id
    },
    uploadDisabled () {
      return this.loading || !this.file 
          || (this.useVendorSelections && (!this.payload.vendor_party_id || !this.payload.subvendor_party_id))
          || (this.useCategoryManager && !this.payload.category_manager_id)
          || (this.tenant === 'alliance-retail-group' && this.payload.ad_group_party_ids.length === 0)
    },
    contractDatesConfig() {
      return [
        { 
          label: 'Ad 1 Start/End', value: 'ad_1', billing_mode: 'AD', cols: 4,
          disabled: (this.readOnly && (!this.payload.ad_1?.start_dt && !this.payload.ad_1?.end_dt))
        },
        { 
          label: 'Ad 2 Start/End', value: 'ad_2', billing_mode: 'AD', cols: 4, 
          disabled: this.readOnly ? (!this.payload.ad_2?.start_dt && !this.payload.ad_2?.end_dt) : !(this.payload.ad_1.start_dt && this.payload.ad_1.end_dt),
          min: this.payload.ad_1?.end_dt
        },
        { 
          label: 'Ad 3 Start/End', value: 'ad_3', billing_mode: 'AD', cols: 4,
          disabled: this.readOnly ? (!this.payload.ad_3?.start_dt && !this.payload.ad_3?.end_dt) : !(this.payload.ad_2.start_dt && this.payload.ad_2.end_dt),
          min: this.payload.ad_2?.end_dt
        },
        { label: 'TPR Start/End', value: 'tpr', billing_mode: 'TPR', disabled: (this.readOnly && (!this.payload.tpr?.start_dt && !this.payload.tpr?.end_dt)) },
        { label: 'EDLC Start/End', value: 'edlc', billing_mode: 'EDLC', disabled: (this.readOnly && (!this.payload.edlc?.start_dt && !this.payload.edlc?.end_dt)) },
        { label: 'AMAP Start/End', value: 'amap', billing_mode: 'AMAP', off_invoice: true, disabled: (this.readOnly && (!this.payload.amap?.start_dt && !this.payload.amap?.end_dt)) },
        { label: 'EBA Start/End', value: 'eba', billing_mode: 'EBA', off_invoice: true, disabled: (this.readOnly && (!this.payload.eba?.start_dt && !this.payload.eba?.end_dt)) },
        { label: 'DSD OI Start/End', value: 'dsd_off_invoice', billing_mode: 'OI', disabled: (this.readOnly && (!this.payload.dsd_off_invoice?.start_dt && !this.payload.dsd_off_invoice?.end_dt)) || !this.payload.dsd, off_invoice: true }
      ]
    },
    contractBoolsConfig () {
      return [
        { label: 'Perishable', value: 'perishable', disabled: this.payload.dsd === true },
        { label: 'DSD', value: 'dsd', disabled: [this.payload.perishable, this.payload.multi_vendor].some(v => !!v) },
        { label: 'Private Label', value: 'multi_vendor', disabled: this.payload.dsd === true }
      ]
    },
    noPromoDatesSelected () {
      return (this.$auth.tenant === 'alliance-retail-group') && !this.contractDatesConfig.some(config => {
        return (!config.off_invoice) 
          && this.payload[config.value]?.start_dt && this.payload[config.value]?.end_dt
      })
    },
    adDatesSelected() {
      return (this.$auth.tenant === 'alliance-retail-group') && this.contractDatesConfig.some(config => {
        return (config.value.includes('ad')) 
          && this.payload[config.value]?.start_dt && this.payload[config.value]?.end_dt
      })
    },
    tprDatesSelected() {
      return (this.$auth.tenant === 'alliance-retail-group') && this.contractDatesConfig.some(config => {
        return (['tpr', 'edlc'].includes(config.value)) 
          && this.payload[config.value]?.start_dt && this.payload[config.value]?.end_dt
      })
    }
  },
  methods: {
    handleFile(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.file.upload_file = e.target.result
        }
        reader.readAsArrayBuffer(file)
      }
    },
    reset() {
      this.file = null
    },
    getMinStartDate (dateRanges) {
      let min = null
      dateRanges.forEach(ad => {
        if (this.payload[ad]?.start_dt) {
          const curr = new Date(this.payload[ad]?.start_dt)
          if (!min || curr < min) {
            min = curr
          }
        }
      })
      return min
    },
    getMaxEndDate (dateRanges) {
      let max = null
      dateRanges.forEach(ad => {
        if (this.payload[ad]?.end_dt) {
          const curr = new Date(this.payload[ad]?.end_dt)
          if (!max || curr > max) {
            max = curr
          }
        }
      })
      return max
    },
    validateDateRanges (min, max, dateRangeValues, type) {
      let valid = true
      dateRangeValues.forEach(rangeValue => {
        if (this.payload[rangeValue]?.start_dt && this.payload[rangeValue]?.end_dt) {
          const start = new Date(this.payload[rangeValue]?.start_dt)
          const end = new Date(this.payload[rangeValue]?.end_dt)
          const validRange = (start <= min) && (end >= max)
          if (!validRange) {
            this.emitAlert(true, 'warning', `Please ensure ${this.contractDatesConfig.find(config => config.value === rangeValue).label} date overlaps the entire ${type} date range.`)
            valid = false
          }
        }
      })
      return valid
    },
    checkLateSubmission(value, maxNumberOfDays) {
      let late = false
      const currentDate = new Date()
      if (this.payload[value]?.start_dt) {
        const startDate = new Date(this.payload[value]?.start_dt)
        const diff = Math.abs((startDate - currentDate) / (1000 * 3600 * 24))
        if (diff < maxNumberOfDays) late = true
      }
      return late
    },
    checkLateSubmissions() {
      let warning = ''
      const promoValues = ['tpr', 'edlc']
      let latePromoSubmissionNames = ''
      for (const pv of promoValues) {
        const late = this.checkLateSubmission(pv, 28)
        if (late) {
          if (!latePromoSubmissionNames) latePromoSubmissionNames += `${pv.toUpperCase()}`
          else latePromoSubmissionNames += ` / ${pv.toUpperCase()}`
        }
      }
      if (latePromoSubmissionNames) warning += `${latePromoSubmissionNames} start date entered is less than ${28 / 7} weeks from current date. `
      
      let lateAdNames = ''
      const adValues = [
        { value: 'ad_1', name: 'Ad 1' },
        { value: 'ad_2', name: 'Ad 2' },
        { value: 'ad_3', name: 'Ad 3' }
      ]
      for (const av of adValues) {
        const late = this.checkLateSubmission(av.value, 56)
        if (late) {
          if (!lateAdNames) lateAdNames += `${av.name}`
          else lateAdNames += ` / ${av.name}`
        }
      }
      if (lateAdNames) warning += `${lateAdNames} start date(s) entered is less than ${56 / 7} weeks from current date. `

      if (warning) {
        warning += 'Please reach out to ARG staff for review and approval after uploading.'
        this.emitAlert(true, 'warning', warning)
      }
    },
    uploadFile() {
      if (this.tenant !== 'alliance-retail-group') {
        return this.dialog = true
      }
      if (this.noPromoDatesSelected) {
        this.showDateError = true
        return this.emitAlert(true, 'warning', 'No promo start/end dates selected')
      }
      if (this.payload.ad_billback_category_id && !this.adDatesSelected) {
        return this.emitAlert(true, 'warning', 'Ad billback category selected but no ad start/end dates entered')
      }
      if (this.payload.digital_coupon && !this.adDatesSelected) {
        return this.emitAlert(true, 'warning', 'Digital coupon checked but no ad start/end dates entered')
      }
      if (((this.payload.lump_sum_allowance || 0) > 0) && !this.adDatesSelected) {
        return this.emitAlert(true, 'warning', 'Ad fee detected but no ad start/end dates entered')
      }
      if (((this.payload.tpr_fee || 0) > 0) && !this.tprDatesSelected) {
        return this.emitAlert(true, 'warning', 'TPR fee detected but no TPR or EDLC start/end dates entered')
      }
      if (this.payload.multi_vendor && this.payload.ad_billback_category_id !== this.nonAllowanceCategoryId) {
        return this.emitAlert(true, 'warning', 'Private label checked but Ad Billback Non-Allowance not selected')
      }
      let validDates = false
      for (const config of this.contractDatesConfig) {
        const field = this.$refs[`${config.value}_dates`][0]
        if (this.payload[config.value].start_dt && this.payload[config.value].end_dt) {
          validDates = field.validate()
        }
      }
      if (validDates) {
        const minAdStart = this.getMinStartDate(['ad_1', 'ad_2', 'ad_3'])
        const maxAdEnd = this.getMaxEndDate(['ad_1', 'ad_2', 'ad_3'])
        const minAllowanceStart = this.getMinStartDate(['ad_1', 'ad_2', 'ad_3', 'tpr', 'edlc'])
        const maxAllowanceEnd = this.getMaxEndDate(['ad_1', 'ad_2', 'ad_3', 'tpr', 'edlc'])
        
        let validAdDependents = true
        let validAllowanceDependents = true
        if (this.payload['ad_1']?.start_dt || this.payload['ad_2']?.start_dt || this.payload['ad_3']?.start_dt ) {
          validAdDependents = this.validateDateRanges(minAdStart, maxAdEnd, ['tpr', 'edlc'], 'ad')
        }
        if (this.payload['ad_1']?.start_dt || this.payload['ad_2']?.start_dt || this.payload['ad_3']?.start_dt || this.payload['tpr']?.start_dt || this.payload['edlc']?.start_dt ) {
          validAllowanceDependents = this.validateDateRanges(minAllowanceStart, maxAllowanceEnd, ['amap', 'eba', 'dsd_off_invoice'], 'allowance')
        }
        if (!validAdDependents || !validAllowanceDependents) return
      }
      const validFields = validDates && this.$refs.form.validate()
      if (!validFields) {
        return this.emitAlert(true, 'warning', 'Please check your form for errors')
      }
      this.checkLateSubmissions()
      this.dialog = true
    },
    closeUploadDialog() {
      this.dialog = false
    },
    confirmUpload() {
      this.closeUploadDialog()
      this.upload()
    },
    multiVendorChange(newValue) {
      if (newValue) {
        this.payload.vendor_party_id = null
        this.payload.subvendor_party_id = null
      }
    },
    resetSpecialAdLength() {
      this.payload.special_ad_length = false
    },
    async upload() {
      try {
        await this.processFile(this.file)
        this.emitAlert(true, 'success', 'File(s) have been successfully uploaded and are now being processed.')
        this.$emit('closeUpload', true)
        this.reset()
      } catch (err) {
        this.handleError(err)
      }
    },
    async processFile(file) {
      const blob = new Blob([file.upload_file], { type: `${file.type}` })
      const formData = new FormData()
      const pathEnv = `${process.env.VUE_APP_FILE_PATH}`
      const today = (new Date()).toISOString().split('T')[0]

      formData.append('path', `${pathEnv}/${this.tenant}/uploads/${today}/`)
      formData.append('name', `${file.name}`)
      formData.append('upload_file', blob, `${file.name}`)

      if (this.tenant === 'alliance-retail-group') {
        this.payload.non_allowance = this.nonAllowanceSelected
        const { lump_sum_allowance, tpr_fee } = this.payload
        const numericAdFee = this.getNumericPrice(lump_sum_allowance)
        const numericTPRFee = this.getNumericPrice(tpr_fee)
        this.payload.lump_sum_allowance = (numericAdFee === 0) ? null : numericAdFee
        this.payload.tpr_fee = (numericTPRFee === 0) ? null : numericTPRFee
      }
      formData.append('payload', JSON.stringify(this.payload))

      if (this.reupload) {
        return ContractUpload.rerunContractUpload(formData, this.uploadId)
      } else {
        return ContractUpload.uploadContract(formData)
      }
    },
    async downloadTemplate() {
      try {
        this.emitAlert(true, 'info', "Template downloading")
        const response = await ContractUpload.downloadTemplate()
        if (response?.data?.url) {
          const url = response.data.url
          window.open(url, '_blank')
        }
      } catch (err) {
        console.error(err)
        this.emitAlert(true, 'error', "Error while downloading template")
      }
    },
    async downloadContract () {
      this.downloading = true
      try {
        const { data } =  await ContractUpload.downloadContract({ upload_id: this.selectedContract.upload_id })
        if (data?.url) {
          location.href = data.url
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.downloading = false
      }
    },
    async updateSubvendors() {
      this.loadingSubvendors = true
      try {
        this.subvendors = []
        const subvendorParties = await this.getDownstreamSubvendorParties(this.payload.vendor_party_id)
        if (subvendorParties.length > 0) {
          this.subvendors = subvendorParties.sort((a, b) => a.name.localeCompare(b.name))
        }
      } catch (err) {
        this.handleError(err)
      }
      this.loadingSubvendors = false
    },
    async getDownstreamSubvendorParties (vendor_id) {
      const downstreamSubvendorRes = await PartyRel.downstreamRelationshipSearch({ to_party_ids: [vendor_id] })
      if (downstreamSubvendorRes.data?.length !== 0) {
        const downstreamSubvendors = downstreamSubvendorRes.data
          .filter(rel => rel.from_rel_type === 'CHILD_VENDOR_FOR')
          .map(rel => {
            let subvendor = {}
            subvendor.name = rel.from_party_name
            subvendor.party_name = `${subvendor.name}`
            subvendor.id = rel.from_party_id
            return subvendor
          })
        return downstreamSubvendors
      } else {
        return []
      }
    },
  }
}
</script>