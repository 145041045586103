var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-autocomplete", {
        attrs: {
          value: _vm.value,
          items: _vm.vendors,
          disabled: _vm.disabled,
          readonly: _vm.readOnly && !_vm.disabled,
          inert: _vm.readOnly,
          "item-text": "name",
          "item-value": "id",
          "item-color": "action",
          label: "Vendor*",
          loading: _vm.loading,
          "search-input": _vm.vendorSearch,
          "background-color": "input",
          rules: [
            function(v) {
              return !!v || "*Required field"
            }
          ],
          "validate-on-blur": "",
          hint:
            _vm.searchEnabled && !_vm.readOnly
              ? "Start typing to search"
              : null,
          "hide-details": "auto",
          "hide-no-data": "",
          "cache-items": "",
          clearable: !_vm.readOnly,
          dense: !_vm.readOnly,
          outlined: !_vm.readOnly
        },
        on: {
          "update:searchInput": function($event) {
            _vm.vendorSearch = $event
          },
          "update:search-input": function($event) {
            _vm.vendorSearch = $event
          },
          change: function($event) {
            return _vm.$emit("update:value", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }