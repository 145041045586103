var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", "max-width": "900px" } },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { staticClass: "pl-4 pt-4", attrs: { dense: "", flat: "" } },
                [
                  _c("v-icon", { staticClass: "mr-2 ml-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.readOnly
                            ? "mdi-text-box-search-outline"
                            : "mdi-file-upload-outline"
                        ) +
                        " "
                    )
                  ]),
                  _c("v-toolbar-title", { staticClass: "title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.readOnly
                            ? "Contract Upload Details"
                            : "Upload Contract"
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto pr-1", attrs: { cols: "auto" } },
                    [
                      _vm.readOnly || _vm.$auth.tenant !== "jbg"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                loading: _vm.downloading,
                                disabled: _vm.downloading,
                                color: _vm.readOnly ? "primary" : "info"
                              },
                              on: {
                                click: function($event) {
                                  _vm.readOnly
                                    ? _vm.downloadContract()
                                    : _vm.downloadTemplate()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-file-download-outline")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.readOnly ? "Contract File" : "Template"
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  !_vm.readOnly
                    ? _c(
                        "v-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.loading,
                                disabled: _vm.uploadDisabled
                              },
                              on: { click: _vm.uploadFile }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-file-upload-outline")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.reupload ? "Reupload" : "Upload") +
                                  " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "pr-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("closeUpload")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "py-4" },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "pt-0 mt-0 pl-2 ml-2",
                          staticStyle: { color: "grey", "font-size": "16px" }
                        },
                        [_vm._v(" " + _vm._s(_vm.modalSubtitle) + " ")]
                      ),
                      [
                        _c(
                          "v-form",
                          {
                            ref: "form",
                            attrs: { "lazy-validation": "" },
                            model: {
                              value: _vm.valid,
                              callback: function($$v) {
                                _vm.valid = $$v
                              },
                              expression: "valid"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols:
                                        _vm.$auth.tenant ===
                                        "alliance-retail-group"
                                          ? undefined
                                          : 8
                                    }
                                  },
                                  [
                                    _vm.readOnly
                                      ? [
                                          _c("v-text-field", {
                                            attrs: {
                                              inert: _vm.readOnly,
                                              label: "File Name",
                                              dense: "",
                                              readonly: "",
                                              "hide-details": "",
                                              "prepend-icon": "mdi-paperclip"
                                            },
                                            model: {
                                              value:
                                                _vm.selectedContract.file_name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.selectedContract,
                                                  "file_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "selectedContract.file_name"
                                            }
                                          })
                                        ]
                                      : [
                                          _c("v-file-input", {
                                            attrs: {
                                              inert: _vm.readOnly,
                                              outlined: "",
                                              dense: "",
                                              "hide-details": "auto",
                                              "truncate-length": "100",
                                              label: "File*",
                                              clearable: "",
                                              accept: ".xlsx"
                                            },
                                            on: { change: _vm.handleFile },
                                            model: {
                                              value: _vm.file,
                                              callback: function($$v) {
                                                _vm.file = $$v
                                              },
                                              expression: "file"
                                            }
                                          })
                                        ]
                                  ],
                                  2
                                ),
                                _vm.tenant === "pricechopper"
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "pt-0",
                                        attrs: { cols: "3" }
                                      },
                                      [
                                        _vm.showMultiVendor
                                          ? _c("v-checkbox", {
                                              staticClass: "mt-0 pt-0",
                                              attrs: {
                                                label: "Private Label Contract",
                                                color: "primary",
                                                dense: "",
                                                disabled:
                                                  _vm.readOnly &&
                                                  !_vm.payload.multi_vendor,
                                                readonly:
                                                  _vm.readOnly &&
                                                  _vm.payload.multi_vendor,
                                                inert: _vm.readOnly,
                                                "hide-details": "",
                                                ripple: false
                                              },
                                              on: {
                                                change: _vm.multiVendorChange
                                              },
                                              model: {
                                                value: _vm.payload.multi_vendor,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.payload,
                                                    "multi_vendor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payload.multi_vendor"
                                              }
                                            })
                                          : _vm._e(),
                                        _c("v-checkbox", {
                                          attrs: {
                                            label: "Non-Allowance Contract",
                                            color: "primary",
                                            dense: "",
                                            disabled:
                                              _vm.readOnly &&
                                              !_vm.payload.non_allowance,
                                            readonly:
                                              _vm.readOnly &&
                                              _vm.payload.non_allowance,
                                            inert: _vm.readOnly,
                                            "hide-details": "",
                                            ripple: false
                                          },
                                          model: {
                                            value: _vm.payload.non_allowance,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.payload,
                                                "non_allowance",
                                                $$v
                                              )
                                            },
                                            expression: "payload.non_allowance"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.tenant === "alliance-retail-group"
                                  ? _vm._l(_vm.contractBoolsConfig, function(
                                      obj
                                    ) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: obj.value,
                                          attrs: {
                                            "align-self": "center",
                                            cols: "auto"
                                          }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "ma-auto",
                                            attrs: {
                                              label: obj.label,
                                              color: "primary",
                                              dense: "",
                                              readonly:
                                                _vm.readOnly &&
                                                _vm.payload["" + obj.value] ===
                                                  true,
                                              disabled:
                                                (_vm.readOnly &&
                                                  !_vm.payload[
                                                    "" + obj.value
                                                  ]) ||
                                                obj.disabled,
                                              inert: _vm.readOnly,
                                              "hide-details": "",
                                              ripple: false
                                            },
                                            on: {
                                              change: function(e) {
                                                return obj.value ===
                                                  "multi_vendor"
                                                  ? _vm.multiVendorChange(e)
                                                  : null
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.payload["" + obj.value],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.payload,
                                                  "" + obj.value,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "payload[`${obj.value}`]"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    })
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "v-container",
                              { staticClass: "px-4", attrs: { fluid: "" } },
                              [
                                _vm.useVendorSelections
                                  ? _c(
                                      "v-row",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { dense: "" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("VendorSingleSelect", {
                                              attrs: {
                                                disabled:
                                                  _vm.payload.multi_vendor,
                                                readOnly: _vm.readOnly,
                                                value:
                                                  _vm.payload.vendor_party_id
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.payload,
                                                    "vendor_party_id",
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                disabled: _vm.subvendorDisabled,
                                                items: _vm.subvendors,
                                                "item-text": "name",
                                                "item-value": "id",
                                                "item-color": "action",
                                                label: "Subvendor*",
                                                loading: _vm.loadingSubvendors,
                                                rules: [
                                                  function(v) {
                                                    return _vm.payload
                                                      .vendor_party_id
                                                      ? !!v || "Required field"
                                                      : true
                                                  }
                                                ],
                                                "validate-on-blur": "",
                                                clearable: !_vm.readOnly,
                                                readonly:
                                                  _vm.readOnly &&
                                                  Boolean(
                                                    _vm.payload
                                                      .subvendor_party_id
                                                  ),
                                                inert: _vm.readOnly,
                                                "background-color": "#fff",
                                                dense: !_vm.readOnly,
                                                outlined: !_vm.readOnly,
                                                "hide-details": "auto"
                                              },
                                              model: {
                                                value:
                                                  _vm.payload
                                                    .subvendor_party_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.payload,
                                                    "subvendor_party_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payload.subvendor_party_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-row",
                                  { staticClass: "pb-2", attrs: { dense: "" } },
                                  [
                                    _vm.useCategoryManager
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items: _vm.categoryManagers,
                                                "item-text": "name",
                                                "item-value": "id",
                                                "item-color": "action",
                                                label: "Category Manager*",
                                                clearable: !_vm.readOnly,
                                                rules: [
                                                  function(v) {
                                                    return (
                                                      !!v || "Required field"
                                                    )
                                                  }
                                                ],
                                                disabled:
                                                  _vm.readOnly &&
                                                  !_vm.payload
                                                    .category_manager_id,
                                                readonly:
                                                  _vm.readOnly &&
                                                  Boolean(
                                                    _vm.payload
                                                      .category_manager_id
                                                  ),
                                                inert: _vm.readOnly,
                                                "validate-on-blur": "",
                                                "background-color": "#fff",
                                                dense: !_vm.readOnly,
                                                outlined: !_vm.readOnly,
                                                "hide-details": "auto"
                                              },
                                              model: {
                                                value:
                                                  _vm.payload
                                                    .category_manager_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.payload,
                                                    "category_manager_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payload.category_manager_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.tenant === "alliance-retail-group"
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("ContractAdGroups", {
                                              attrs: {
                                                value:
                                                  _vm.payload
                                                    .ad_group_party_ids,
                                                readOnly: _vm.readOnly
                                              },
                                              on: {
                                                "update:value": function(
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.payload,
                                                    "ad_group_party_ids",
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm.tenant === "alliance-retail-group"
                                  ? [
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Ad Fee",
                                                  type: "number",
                                                  min: "0.00",
                                                  step: ".01",
                                                  prefix: "$",
                                                  disabled:
                                                    _vm.readOnly &&
                                                    !_vm.payload
                                                      .lump_sum_allowance,
                                                  readonly:
                                                    _vm.readOnly &&
                                                    _vm.payload
                                                      .lump_sum_allowance !=
                                                      null,
                                                  outlined: !_vm.readOnly,
                                                  dense: "",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload
                                                      .lump_sum_allowance,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "lump_sum_allowance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.lump_sum_allowance"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "TPR Fee",
                                                  type: "number",
                                                  min: "0.00",
                                                  step: ".01",
                                                  prefix: "$",
                                                  disabled:
                                                    _vm.readOnly &&
                                                    !_vm.payload.tpr_fee,
                                                  readonly:
                                                    _vm.readOnly &&
                                                    _vm.payload.tpr_fee != null,
                                                  outlined: !_vm.readOnly,
                                                  dense: "",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value: _vm.payload.tpr_fee,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "tpr_fee",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "payload.tpr_fee"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items:
                                                    _vm.adBillbackPromoCategories,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  "item-color": "action",
                                                  label: "Ad Billback Category",
                                                  clearable: !_vm.readOnly,
                                                  disabled:
                                                    _vm.readOnly &&
                                                    !_vm.payload
                                                      .ad_billback_category_id,
                                                  readonly:
                                                    _vm.readOnly &&
                                                    Boolean(
                                                      _vm.payload
                                                        .ad_billback_category_id
                                                    ),
                                                  inert: _vm.readOnly,
                                                  "background-color": "#fff",
                                                  dense: "",
                                                  outlined: !_vm.readOnly,
                                                  "hide-details": "auto"
                                                },
                                                on: {
                                                  "click:clear":
                                                    _vm.resetSpecialAdLength,
                                                  change:
                                                    _vm.resetSpecialAdLength
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload
                                                      .ad_billback_category_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "ad_billback_category_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.ad_billback_category_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "4",
                                                "align-self": "center"
                                              }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "my-auto",
                                                attrs: {
                                                  label: "Special Ad Length",
                                                  color: "primary",
                                                  dense: "",
                                                  readonly:
                                                    _vm.readOnly &&
                                                    _vm.payload
                                                      .special_ad_length,
                                                  disabled:
                                                    !_vm.payload
                                                      .ad_billback_category_id ||
                                                    (_vm.readOnly &&
                                                      !_vm.payload
                                                        .special_ad_length) ||
                                                    [
                                                      "Ad Billback - 1-2 Day Ad",
                                                      "Ad Billback Non-Allowance"
                                                    ].includes(
                                                      _vm.selectedCategoryName
                                                    ),
                                                  inert: _vm.readOnly,
                                                  "hide-details": "",
                                                  ripple: false
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload
                                                      .special_ad_length,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "special_ad_length",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.special_ad_length"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: { "align-self": "center" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Digital Coupon Value",
                                                  disabled: !_vm.payload
                                                    .digital_coupon,
                                                  readonly:
                                                    _vm.readOnly &&
                                                    _vm.payload.digital_coupon,
                                                  inert: _vm.readOnly,
                                                  outlined: !_vm.readOnly,
                                                  dense: "",
                                                  "hide-details": "auto"
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload
                                                      .digital_coupon_note,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "digital_coupon_note",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.digital_coupon_note"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "4",
                                                "align-self": "center"
                                              }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "my-auto",
                                                attrs: {
                                                  label: "Digital Coupon",
                                                  color: "primary",
                                                  disabled:
                                                    _vm.readOnly &&
                                                    !_vm.payload.digital_coupon,
                                                  readonly:
                                                    _vm.readOnly &&
                                                    _vm.payload.digital_coupon,
                                                  inert: _vm.readOnly,
                                                  dense: "",
                                                  "hide-details": "",
                                                  ripple: false
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload.digital_coupon,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.payload,
                                                      "digital_coupon",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.digital_coupon"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "my-2" }),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "pt-2",
                                          attrs: { dense: "" }
                                        },
                                        _vm._l(
                                          _vm.contractDatesConfig,
                                          function(config) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: config.value,
                                                staticClass: "py-2",
                                                attrs: {
                                                  cols: config.cols || 4
                                                }
                                              },
                                              [
                                                _c("ContractDates", {
                                                  ref: config.value + "_dates",
                                                  refInFor: true,
                                                  attrs: {
                                                    value:
                                                      _vm.payload[
                                                        "" + config.value
                                                      ],
                                                    readOnly: _vm.readOnly,
                                                    config: config,
                                                    specialAdLength:
                                                      _vm.payload
                                                        .special_ad_length,
                                                    ad_billback_category_id:
                                                      _vm.payload
                                                        .ad_billback_category_id,
                                                    "hide-details": "auto",
                                                    error: _vm.showDateError
                                                  },
                                                  on: {
                                                    "update:value": function(
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.payload,
                                                        "" + config.value,
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.dialog
        ? _c("UploadDialog", {
            attrs: { file: _vm.file },
            on: {
              cancel: _vm.closeUploadDialog,
              confirmUpload: _vm.confirmUpload
            },
            model: {
              value: _vm.dialog,
              callback: function($$v) {
                _vm.dialog = $$v
              },
              expression: "dialog"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }