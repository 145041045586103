<template>
    <v-container fluid class="ma-0 pa-0" >
      <v-autocomplete
        :value="value"
        :items="vendors"
        :disabled="disabled"
        :readonly="readOnly && !disabled"
        :inert="readOnly"
        item-text="name"
        item-value="id"
        item-color="action"
        label="Vendor*"
        :loading="loading"
        :search-input.sync="vendorSearch"
        background-color="input"
        :rules="[v => !!v || '*Required field']"
        validate-on-blur
        :hint="searchEnabled && !readOnly ? 'Start typing to search' : null"
        hide-details="auto"
        hide-no-data
        cache-items
        :clearable="!readOnly"
        :dense="!readOnly"
        :outlined="!readOnly"
        @change="$emit('update:value', $event)">
      </v-autocomplete>
    </v-container>
  </template>
  <script>
  import Search from '@/axios/search-endpoint'
  // mixins
  import { userAccess } from '@/mixins/user-access'
  import { displayAlert } from '@/mixins/alert'
  // third-party
  import { debounce, cloneDeep } from 'lodash'
  
  export default {
    data() {
      return {
        vendors: [],
        vendorSearch: '',
        loading: false
      }
    },
    name: 'VendorSingleSelect',
    mixins: [userAccess, displayAlert],
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      readOnly: Boolean,
      value: String
    },
    watch: {
       vendorSearch: {
        handler: debounce(function (newValue) {
          if (this.searchEnabled && !this.value && newValue ) {
            this.searchVendors()
          }
        }, 500)
      },
      userRelatedParties: {
        handler (newValue) {
          if (newValue && newValue.length > 0 && this.limitAccessByRelatedVendors) {
            this.vendors.push(...this.userDropdownParties)
          }
        },
        deep: true
      },
    },
    async created() {
      if (this.limitAccessByRelatedVendors && this.userRelatedParties.length > 0) {
        this.vendors.push(...this.userDropdownParties)
      }
      if (this.searchEnabled && this.value) {
        await this.searchVendors(this.value, 'or')
      }
    },
    computed: {
      userDropdownParties () {
        const relatedVendors = cloneDeep(this.relatedVendorParties)
        return relatedVendors.flatMap(vendor => this.formatParty(vendor))
      },
      searchEnabled () {
        return !this.limitAccessByRelatedVendors
      }
    },
    methods: {
      async searchVendors (searchTerm = '', operator = 'and') {
        if (this.limitAccessByRelatedVendors && this.relatedVendorParties.length === 0) return
        if (this.vendorSearch) this.loading = true
        const term = this.vendorSearch || searchTerm
        let vendors = []
        try {
          const res = await Search.customerSearch(term, operator)
          if (res && res.data && res.data.length > 0) {
            vendors = res.data.flatMap(party => {
              return (party.party_type) === 'VENDOR'
                ? this.formatParty(party)
                : []
            })
          }
          this.vendors = [...this.vendors, ...vendors]
        } catch (err) {
          this.handleError(err)
        } finally {
          this.loading = false
        }
      },
      
      formatParty(party) {
        let display_name = party.party_name || party.name
        const partyType = party.party_type_constant || party.party_type
        if (party.attributes) {
          const attrVal = party.attributes.VENDOR_ID || ''
          display_name = `${attrVal} ${display_name}`
        }
        party.name = display_name
        party.party_type_name = this.formatPartyType(partyType)
        if (!party.id) party.id = party.party_id
        return party
      },
      formatPartyType (str) {
        str = str.toLowerCase().replaceAll('_', ' ')
        const parts = str.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1))
        return parts.join(' ')
      }
    }
  }
  </script>
  