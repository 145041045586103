var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form", staticClass: "ma-0 pa-0", attrs: { "lazy-validation": "" } },
    [
      _c(
        "v-menu",
        {
          attrs: {
            transition: "scale-transition",
            "offset-y": "",
            "close-on-content-click": false,
            "nudge-top": _vm.hideDetails ? "50" : "25",
            "max-width": "290px",
            "min-width": "290px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          ref: "date_input",
                          attrs: {
                            inert: _vm.readOnly,
                            value: _vm.formattedDates,
                            label: _vm.config.label,
                            "prepend-inner-icon": "mdi-calendar",
                            dense: !_vm.readOnly,
                            "background-color": "#fff",
                            outlined: !_vm.readOnly,
                            "hide-details": _vm.hideDetails,
                            disabled: _vm.config.disabled,
                            readonly: _vm.readOnly && !_vm.config.disabled,
                            rules: [_vm.isValidDateRange],
                            error: _vm.error && !_vm.config.off_invoice,
                            "validate-on-blur": "",
                            clearable: !_vm.readOnly
                          },
                          on: { "click:clear": _vm.clearDates }
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      !_vm.readOnly ? on : null
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.date_picker,
            callback: function($$v) {
              _vm.date_picker = $$v
            },
            expression: "date_picker"
          }
        },
        [
          _c("v-date-picker", {
            attrs: {
              range: "",
              "show-current": _vm.promo.start_dt || _vm.config.min || true,
              "allowed-dates": _vm.allowedDates,
              min: _vm.promo.start_dt || _vm.config.min,
              scrollable: "",
              "no-title": ""
            },
            model: {
              value: _vm.dates,
              callback: function($$v) {
                _vm.dates = $$v
              },
              expression: "dates"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }