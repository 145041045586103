var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: _vm.handleAutocompleteClick,
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.handleAutocompleteClick($event)
        }
      }
    },
    [
      _c("v-autocomplete", {
        attrs: {
          value: _vm.value,
          items: _vm.activeAdGroups,
          "item-text": "name",
          "item-value": "id",
          "item-color": "action",
          label: "Ad Groups*",
          clearable: !_vm.readOnly,
          chips: "",
          "small-chips": "",
          "background-color": "#fff",
          rules: [
            function(v) {
              return (Array.isArray(v) && v.length > 0) || "Required field"
            }
          ],
          "validate-on-blur": "",
          readonly: _vm.readOnly,
          dense: !_vm.readOnly,
          multiple: "",
          outlined: !_vm.readOnly,
          "hide-details": "auto"
        },
        on: {
          change: function($event) {
            return _vm.$emit("update:value", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-item",
            fn: function() {
              return [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-action",
                      [
                        _c("v-checkbox", {
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-subtitle-2 ml-8 text-black font-weight-bold text--black"
                                    },
                                    [_vm._v(" Select All ")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.allAdGroupsSelected,
                            callback: function($$v) {
                              _vm.allAdGroupsSelected = $$v
                            },
                            expression: "allAdGroupsSelected"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-divider")
              ]
            },
            proxy: true
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              var ref_attrs = ref.attrs
              var inputValue = ref_attrs.inputValue
              var disabled = ref_attrs.disabled
              var ripple = ref_attrs.ripple
              return [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { disabled: disabled }
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  color: "action",
                                  "input-value": inputValue,
                                  disabled: disabled,
                                  ripple: ripple
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(item.name) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ],
                  2
                )
              ]
            }
          },
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c(
                  "div",
                  [
                    _vm.allAdGroupsSelected && index === 1
                      ? _c("v-chip", { attrs: { small: "" } }, [
                          _c("span", [_vm._v(" All Groups Selected ")])
                        ])
                      : index < 3 && !_vm.allAdGroupsSelected
                      ? _c("v-chip", { attrs: { small: "" } }, [
                          _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])
                        ])
                      : index === 3 && !_vm.allAdGroupsSelected
                      ? _c("v-chip", { attrs: { small: "" } }, [
                          _c("span", [
                            _vm._v(
                              " " + _vm._s("+" + (_vm.value.length - 3)) + " "
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm.adGroupModal
        ? _c("AdGroupsModal", {
            attrs: { value: _vm.adGroupModal, selectedAdGroups: _vm.value },
            on: { closeDialog: _vm.closeAdGroupModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }