<template>
  <v-form ref="form" lazy-validation class="ma-0 pa-0">
    <v-menu
      v-model="date_picker"
      transition="scale-transition"
      offset-y
      :close-on-content-click="false"
      :nudge-top="hideDetails ? '50' : '25'"
      max-width="290px"
      min-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :inert="readOnly"
          :value="formattedDates"
          :label="config.label"
          ref="date_input"
          prepend-inner-icon="mdi-calendar"
          :dense="!readOnly"
          background-color="#fff"
          :outlined="!readOnly"
          :hide-details="hideDetails"
          :disabled="config.disabled"
          :readonly="readOnly && !config.disabled"
          :rules="[isValidDateRange]"
          :error="error && !config.off_invoice"
          validate-on-blur
          :clearable="!readOnly"
          @click:clear="clearDates"
          v-bind="attrs"
          v-on="!readOnly ? on : null">
        </v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        range
        :show-current="promo.start_dt || config.min || true"
        :allowed-dates="allowedDates"
        :min="promo.start_dt || config.min"
        scrollable
        no-title>
      </v-date-picker>
    </v-menu>
  </v-form>
</template>
<script>
// mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
// Third-Party Helpers
import { addMinutes } from 'date-fns'
export default {
  data () {
    return {
      dates: [],
      date_picker: false,
      dateRulesConfig: {
        TPR: { startDay: 0, endDay: 6, minDays: 21 },
        EDLC: { startDay: 0, endDay: 6, minDays: 21 },
        AD: { startDay: 1, endDay: 0 },
      },
      dateExcludedCategories: ['Ad Billback - 1-2 Day Ad', 'Ad Billback Non-Allowance'],
      adLengthRestrictions: {
        'Ad Billback - 1-2 Day Ad': {
          minDays: 1,
          maxDays: 2
        },
        'Ad Billback - 1 Week Ad': {
          minDays: 3,
          maxDays: 13
        },
        'Ad Billback - 2 Week Ad': {
          minDays: 14,
          maxDays: 14
        }
      }
    }
  },
  name: 'ContractDates',
  mixins: [displayAlert, userAccess],
  props: {
    value: Object,
    config: Object,
    ad_billback_category_id: String,
    specialAdLength: Boolean,
    hideDetails: [Boolean, String],
    error: Boolean,
    readOnly: Boolean
  },
  watch: {
    categoryName: {
      handler(newValue, oldValue) {
        if (this.config.billing_mode === 'AD' && (newValue || oldValue)) {
          if (this.promo.end_dt) {
            this.$refs.date_input.validate()
          }
        }
      },
      deep: true
    },
    promo: {
      handler(newValue, oldValue) {
        this.$emit('update:value', this.promo)
        if (newValue.end_dt && !oldValue.end_dt) this.date_picker = false
      },
      deep: true
    }
  },
  created() {
    if (this.value?.start_dt && this.value?.end_dt) {
      this.dates = [this.value.start_dt, this.value.end_dt]
    }
  },
  computed: {
    promo() {
      return { 
        start_dt: this.dates.length > 0 ? this.dates[0] : null,
        end_dt: this.dates.length === 2 ? this.dates[1] : null
      }
    },
    promoCategories () {
      return this.userPromoCategories
    },
    categoryName () {
      return this.promoCategories.find(c => c?.id === this.ad_billback_category_id)?.name
    },
    dateRules () {
      return this.dateRulesConfig[this.config.billing_mode]
    },
    isDateRestrictedCategory () {
      if (this.config.billing_mode === 'AD') {
        return this.ad_billback_category_id && !this.dateExcludedCategories.includes(this.categoryName)
      }
      return Object.keys(this.dateRulesConfig).includes(this.config.billing_mode)
    },
    applyDateRestrictions () {
      return this.isDateRestrictedCategory && !this.specialAdLength
    },
    lengthRules () {
      if (this.config.billing_mode === 'AD' && this.ad_billback_category_id) {
        return this.adLengthRestrictions[this.categoryName]
      }
      return this.dateRules
    },
    applyPromoLengthRestriction () {
      return ['TPR', 'EDLC'].includes(this.config.billing_mode)
        || (this.config.billing_mode === 'AD' && this.ad_billback_category_id && this.adLengthRestrictions[this.categoryName])
    },
    allowedDates() {
      return (this.dates.length === 0) ? this.allowedStartDates : this.allowedEndDates
    },
    allowedStartDates () {
      return this.applyDateRestrictions ? this.isAllowedStartDate : null
    },
    allowedEndDates () {
      return (this.applyDateRestrictions || this.applyPromoLengthRestriction) ? this.isAllowedEndDate : null
    },
    formattedDates() {
      if (this.dates.length > 0) {
        const formattedDates = this.dates.map(date => {
          const [year, month, day] = date.split('-')
          return `${month}/${day}/${year}`
        })
        return formattedDates.join(' - ')
      }
      return null
    }
  },
  methods: {
    clearDates() {
      this.dates = []
    },
    validate () {
      return this.$refs.form.validate()
    },
    isAllowedStartDate (val) {
      const numericDay = this.getNumericDay(val)
      return numericDay === this.dateRules.startDay
    },
    isAllowedEndDate (val) {
      let allowed = true
      const numericDay = this.getNumericDay(val)
      if (this.applyDateRestrictions && this.dateRules.endDay != null ) {
        allowed = numericDay === this.dateRules.endDay
      }
      if (this.applyPromoLengthRestriction && this.promo.start_dt) {
        allowed = allowed && this.validatePromoDateLength(this.promo.start_dt, val)
      }
      return allowed
    },
    getNumericDay (value) {
      let date = new Date(value)
      date = addMinutes(date, date.getTimezoneOffset())
      return date.getDay()
    },
    getTimezoneOffset (value) {
      let date = new Date(value)
      return addMinutes(date, date.getTimezoneOffset())
    },
    isValidStartDate (value) {
      if (value) {
        if (this.applyDateRestrictions && !this.isAllowedStartDate(value)) {
          return 'Invalid start day for promo type/category'
        }
        if (!this.isValidYear(value)) {
          return 'Invalid year value'
        }
        if (this.promo.end_dt) {
          const validDates = this.validatePromoDateRange()
          if (!validDates) {
            return 'Invalid date range for promo type/category'
          }
        }
        return true
      }
      return this.promo.end_dt ? 'Start Date is required' : true
    },
    isValidEndDate (value) {
      if (value) {
        if (this.applyDateRestrictions && !this.isAllowedEndDate(value)) {
          return 'Invalid end day for promo type/category'
        }
        if (!this.isValidYear(value)) {
          return 'Invalid year value'
        }
        if (this.promo.start_dt) {
          const validDates = this.validatePromoDateRange()
          if (!validDates) {
            return 'Invalid date range for promo type/category'
          }
        }
        return true
      }
      return this.promo.start_dt ? 'End Date is required' : true
    },
    isValidYear (value) {
      if (value) {
        let [year] = value.split('-')
        year = Number(year)
        const currentYear = new Date().getFullYear()
        return (year > currentYear - 3) && (year < currentYear + 3)
      }
      return false
    },
    isValidDateRange (textValue) {
      if (textValue) {
        const value = textValue.split(' - ')
        if (Array.isArray(value) && value.length > 0) {
          const start_dt = this.dates[0]
          const validStart = this.isValidStartDate(start_dt)
          if (validStart !== true) return validStart

          if (value.length === 2) {
            const end_dt = this.dates[1]
            const validEnd = this.isValidEndDate(end_dt)
            if (validEnd !== true) return validEnd
          }
        }
      }
      return true
      // return 'Please select a start and end date'
    },
    validatePromoDateRange () {
      let valid = true
      const { start_dt, end_dt } = this.promo
      const startDate = new Date(start_dt)
      const endDate = new Date(end_dt)
      valid = (endDate >= startDate)
      if (this.applyPromoLengthRestriction) {
        valid = valid && this.validatePromoDateLength(startDate, endDate)
      }
      return valid
    },
    validatePromoDateLength (start, end) {
      let valid = true
      const promoLength = ((this.getTimezoneOffset(end) - this.getTimezoneOffset(start)) / (1000 * 3600 * 24)) + 1
      const { minDays, maxDays } = this.lengthRules
      if (minDays) {
        valid = promoLength < minDays ? false : valid
      }
      if (maxDays) {
        valid = promoLength > maxDays ? false : valid
      }
      return valid
    }
  }
}
</script>