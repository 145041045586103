<template>
  <div @click="handleAutocompleteClick" @keyup.enter="handleAutocompleteClick">
  <v-autocomplete
    :value="value"
    :items="activeAdGroups"
    item-text="name"
    item-value="id"
    item-color="action"
    label="Ad Groups*"
    :clearable="!readOnly"
    chips
    small-chips
    background-color="#fff"
    :rules="[v => (Array.isArray(v) && v.length > 0) || 'Required field']"
    validate-on-blur
    :readonly="readOnly"
    :dense="!readOnly"
    multiple
    :outlined="!readOnly"
    hide-details="auto"
    @change="$emit('update:value', $event)">
    <template v-slot:prepend-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox v-model="allAdGroupsSelected">
            <template v-slot:label>
              <span class="text-subtitle-2 ml-8 text-black font-weight-bold text--black">
                Select All
              </span>
            </template>
          </v-checkbox>
        </v-list-item-action>
      </v-list-item>
      <v-divider/>
    </template>
    <template v-slot:item="{ item, attrs: { inputValue, disabled, ripple } }">
      <div style="width: 100%">
        <template>
          <v-list-item class="ma-0 pa-0" :disabled="disabled">
            <v-list-item-action>
              <v-checkbox color="action" :input-value="inputValue" :disabled="disabled" :ripple="ripple"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> 
      </div>
    </template>
    <template v-slot:selection="{ item, index }">
      <div>
        <v-chip v-if="allAdGroupsSelected && index === 1" small>
          <span> All Groups Selected </span>
        </v-chip>
        <v-chip v-else-if="index < 3 && !allAdGroupsSelected" small>
          <span> {{ item.name }} </span>
        </v-chip>
        <v-chip v-else-if="index === 3 && !allAdGroupsSelected" small>
          <span> {{ `+${value.length - 3}` }} </span>
        </v-chip>
      </div>
    </template>
  </v-autocomplete>
  <AdGroupsModal
    v-if="adGroupModal"
    :value="adGroupModal"
    :selectedAdGroups="value"
    @closeDialog="closeAdGroupModal"
  >
  </AdGroupsModal>
</div>
</template>
<script>
// Components
const AdGroupsModal = () => import('@/components/contracts/AdGroupsModal.vue')
// Mixins
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  data() {
    return {
      allAdGroupsSelected: false,
      adGroupModal: false
    }
  },
  name: 'ContractAdGroups',
  components: { AdGroupsModal },
  mixins: [userAccess, utils],
  props: {
    value: Array,
    readOnly: Boolean
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (this.allAdGroupsSelected && newValue.length < oldValue.length) {
          this.allAdGroupsSelected = false
        }
      },
      deep: true
    },
    allAdGroupsSelected(newValue) {
      if (newValue) {
       const allIds = this.activeAdGroups.map(group => group.id)
       this.$emit('update:value', allIds)
      } else if (!newValue && this.value.length === this.activeAdGroups.length) {
        this.$emit('update:value', [])
      }
    }
  },
  computed: {
    activeAdGroups () {
      let groups = this.$store.getters.adGroups.filter((g) => g?.attributes?.AD_GROUP_IS_ACTIVE === 'True')
      if (this.limitAccessByRelatedStores) {
        groups = groups.filter(g => this.userRelatedPartyIds.includes(g.id))
      }
      return this.sortByKey(groups, 'name')
    }
  },
  methods: {
    handleAutocompleteClick() {
      if (this.readOnly) {
        this.adGroupModal = true
      }
    },
    closeAdGroupModal() {
      this.adGroupModal = false
    }
  }
}
</script>
